import React, { useContext } from 'react'
import axios from 'axios'
import { Box, Button, Flex, Portal, Spinner, Tooltip } from '@chakra-ui/react'

import { BsFillSuitHeartFill } from 'react-icons/bs';
import { UserContext } from './userContext';
import Debug from '../utils/Debug';

const AddToFavorite = (props) => {

    const { adId } = props;

    const [loading, setLoading] = React.useState(false);

    const userContext = useContext(UserContext);
    const user = userContext ? userContext.user : null;

    const createFavorite = () => (
        axios.post(
            `${process.env.GATSBY_API_URL}/favorite`,
            { "cmsDocumentId": adId, "userId": user._id })
            .then(function (result) {
                console.log(result.data)
            })

    )

    const removeFavorite = () => (
        axios.delete(
            `${process.env.GATSBY_API_URL}/favorite/`,
            { data: { "cmsDocumentId": adId, "userId": user._id } })
            .then(function (result) {
                console.log(result.data)
            })
    )

    const isFavorite = () => (
        user.favorites.map(item => item.cmsDocumentId)
            .includes(adId) ? true : false
    )

    const handleFavorite = async (e, cmsEntryId) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading(true);
        setTimeout(
            () => setLoading(false),
            5000
        )

        console.log(cmsEntryId);
        console.log('favorite', user.favorites);

        if (
            !isFavorite()
        ) {
            createFavorite();
        } else {
            removeFavorite();
        }
    }

    return (
        user && user.favorites ?
            <>
                <Button
                    onClick={(e) => { handleFavorite(e, props.adId) }}
                    borderRadius='30px'
                    title="Ajouter cette annonce à vos favoris"
                    colorScheme={isFavorite() ? 'green' : 'gray'}
                    size={props.size}
                    p='.25rem 1rem'
                    h='1.75rem'
                >
                    {!loading ?
                        <Box
                            transform={isFavorite() ? 'translateX( 0.5rem )' : 'translateX( -0.5rem )'}
                            transition='transform 200ms ease'
                        >
                            <BsFillSuitHeartFill
                                color={isFavorite() ? 'white' : 'gray.600'}
                                transition='color 200ms ease'
                                size={props.size === 'sm' ? '10px' : '14px'}
                            />
                        </Box>
                        :

                        <Flex>
                            <Spinner size='sm' />
                        </Flex>
                    }

                </Button>
                {/* <Portal>

                    <Box position={'fixed'} top='20'>

                        {user && user.favorites ?
                            <Debug data={user.favorites.map(item => item.cmsDocumentId)} />
                            : null}
                    </Box>

                </Portal> */}
            </>
            :
            <Tooltip
                label='Connectez-vous pour utiliser les favoris'
                hasArrow={true}
            >
                <Box
                    color='gray.300'
                    p={2}
                    h='30px'
                >
                    <BsFillSuitHeartFill />
                </Box>
            </Tooltip>
    )
}

export default AddToFavorite