import * as React from 'react'
import { Box, Button, ButtonGroup, FormControl, FormHelperText, FormLabel, Heading, Input, InputGroup, InputRightAddon, Stack, Text, Textarea } from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import Debug from '../../utils/Debug'
import * as yup from 'yup'
import Axios from 'axios'
import config from '../../../config'

export const AdCommentWizard = ({ adId, userId, cancel }) => {
    return (

        <Formik
            onSubmit={(values) =>
                Axios.post(
                    `${config.apiUrl}/comment/`,
                    values
                ).then(res => cancel())
            }
            initialValues={{
                adId: adId,
                userId: userId,
                price: {}
            }}
            validationSchema={
                yup.object().shape({
                    userId: yup.string().required(),
                    adId: yup.string().required(),
                    price: yup.object().shape({
                        level: yup.string().required(),
                    }),
                    // "price.level": yup.string().required(),
                    comment: yup.string().required(),
                })
            }
        >
            {({ values, errors, touched, setFieldValue }) => (
                <Form>
                    <Stack
                        spacing={10}
                    >

                        <Heading
                            fontSize={'lg'}
                            as={Stack}
                            spacing={3}
                        >
                            <Text
                                as='span'
                            >
                                Capitaine, vous allez donner votre avis sur cette annonce. Cela dans le but d'aider et d'orienter le vendeur pour qu'il vende plus facilement.
                            </Text>
                            <Box>
                                <Text
                                    fontSize={'md'}
                                    as='span'
                                    color='gray.500'
                                    borderBottom='solid 2px'
                                    borderBlockEndColor={'blue.300'}
                                >{' '}Merci d'intervenir avec intelligence et bonhommie (lancé de 🍅 pourries strictement interdit)</Text>
                            </Box>
                        </Heading>

                        <Stack
                            spacing={6}
                        >
                            <Field
                                name='price.level'

                            >
                                {({ field, meta }) => (

                                    <FormControl>
                                        {/* <Debug data={field} /> */}
                                        <FormLabel>Que pensez-vous du prix de cette annonce ?</FormLabel>
                                        <ButtonGroup>
                                            <Button
                                                onClick={() => setFieldValue(field.name, 'low')}
                                                colorScheme={field.value === 'low' ? 'blue' : null}
                                            >Trop bas</Button>
                                            <Button
                                                onClick={() => setFieldValue(field.name, 'good')}
                                                colorScheme={field.value === 'good' ? 'green' : null}
                                            >Adapté</Button>
                                            <Button
                                                onClick={() => setFieldValue(field.name, 'high')}
                                                colorScheme={field.value === 'high' ? 'orange' : null}
                                            >Trop haut</Button>
                                        </ButtonGroup>


                                    </FormControl>
                                )}
                            </Field>

                            {values.price && values.price.level === 'low' || values.price && values.price.level === 'high' ?

                                <Field
                                    name='price.proposition'
                                >
                                    {({ field, meta }) => (

                                        <FormControl>
                                            <FormLabel>Quel serait le prix adapté selon-vous&nbsp;?</FormLabel>
                                            <InputGroup>
                                                <Input
                                                    {...field}
                                                    type='number'
                                                    placeholder='1000'
                                                    minW='70px'
                                                    w={
                                                        field.value && field.value.toString().length ? `${field.value.toString().length * 10 + 35}px` : '40px'
                                                    }
                                                    textAlign='right'
                                                />
                                                <InputRightAddon children={'€'} />
                                            </InputGroup>
                                        </FormControl>
                                    )}
                                </Field>
                                : null}

                            {
                                (
                                    (values.price.level === 'low' || values.price.level === 'high')
                                    && values.price.proposition
                                )
                                    || values.price.level === 'good'
                                    ?

                                    <Field
                                        name='comment'
                                    >
                                        {({ field, meta }) => (

                                            <FormControl>
                                                <FormLabel>
                                                    {values.priceLevel === 'good' ?
                                                        <>Auriez-vous un conseil à donner au vendeur pour accélérer sa transaction&nbsp;?</>
                                                        :
                                                        <>Pouvez-vous expliquer pourquoi selon vous le prix ne convient pas&nbsp;?</>
                                                    }
                                                </FormLabel>
                                                <Textarea minH={40} {...field} />
                                                <FormHelperText>
                                                    Attention Capitaine, sur Wanaboat, on se serre les coudes, on s'entraide, je vous demande donc de rédiger avec une plume sympathique et amicale. Hors de question de dézinguer l'annonce.
                                                    L'idée est d'aider, d'orienter le vendeur dans le cas où il aurait commis une petite erreur d'estimation.
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    </Field>
                                    : null}

                        </Stack>

                        <ButtonGroup>
                            <Button
                                type='submit'
                                isDisabled={Object.keys(touched).length === 0 || Object.keys(errors).length}
                                colorScheme={touched && !Object.keys(errors).length ? 'blue' : null}
                            >
                                Envoyer
                            </Button>
                            <Button
                                variant='link'
                                onClick={cancel}
                            >
                                Annuler
                            </Button>
                        </ButtonGroup>

                    </Stack>
                </Form>
            )}
        </Formik>
    )
}