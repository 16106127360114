import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
// import ReactMarkdown from "react-markdown"
import ResponseDelay from "./responseDelay"
import CopyLink from "./CopyLink"
import './annoncesWysiwyg.css'
import SellerCard from './SellerCard.js'
import logo from "../../static/svg/logo.svg"
import { FormattedMessage, FormattedDate } from 'react-intl';
import PriceCtn from '../price'
import AddToFavorite from '../AddToFavorite'
import DocumentsList from './DocumentList'
import {
    Divider,
    Box,
    Flex,
    Image,
    Text,
    Button,
    Link,
    Stack,
    HStack
} from "@chakra-ui/react";
import { SiFacebook, SiWhatsapp } from 'react-icons/si'
import countryToFlag from "../../utils/countryToFlag.js"

const siteConfig = require('../../../config')


export const AdMeta = ({
    ad,
    context,
    openContact,
    ctaLabel,
    isViewingPictures,
    contentful_id
}) => {

    const Line = ({
        block1,
        block2
    }) => (
        <Flex
            justify={'space-between'}
            borderBottom={'solid 1px'}
            borderBottomColor={'gray.100'}
            alignItems={'center'}
            pb={2}
            px={{ base: 3, lg: 2 }}
        >
            {block1}
            {block2}
        </Flex>
    )


    return (

        <Box
            bg={"white"}
            borderTopRightRadius={10}
            borderTopLeftRadius={{ base: 10, lg: 0 }}

            transform={
                ad.pictures && ad.pictures.url.length && !isViewingPictures ?
                    `translateY(-60px)`
                    : null
            }
        >
            <Box
                mx={0}
                position="relative"
                p={{ base: '.45rem', lg: '.75rem' }}
                zIndex={"tooltip"}
            >
                <Stack
                    backgroundColor="gray.50"
                    borderRadius="6px"
                    textAlign="center"
                    // padding={{ base: "4px", lg: "6px" }}
                    pb={{ base: 2 }}
                >
                    {context === "main-website-ad" || context === "main-website-modal" ?
                        <Button
                            bg="blue.brand"
                            color="white"
                            size="md"
                            w={"100%"}
                            onClick={openContact}
                            _hover={{
                                backgroundColor: "green.400"
                            }}
                        >
                            <FormattedMessage id="main.cta.ad.contact" />
                        </Button>
                        :
                        <Button
                            as={"a"}
                            href={`${process.env.SITE_BASE_URL}/${ad.slug}`}
                            target="_blank"
                            bg="blue.brand"
                            color="white"
                            size="md"
                            w={"100%"}
                            _hover={{
                                backgroundColor: "green.400"
                            }}
                        >
                            {ctaLabel}
                        </Button>
                    }
                    {ad.sellerId ?
                        <ResponseDelay userId={ad.sellerId} />
                        : null}

                </Stack>
                <Stack
                    my={5}
                    mb={0}
                    color={"gray.600"}
                    spacing={{ base: 2, lg: 2 }}
                >

                    <Line
                        block1={<FormattedMessage id="ad.price" />}
                        block2={<PriceCtn value={ad.price} />}
                    />

                    <Line
                        block1={<Box>Publication</Box>}
                        block2={<FormattedDate value={ad.publicationDate} />}
                    />

                    <Line
                        block1={<Box><FormattedMessage id='ad.record.favorite' /></Box>}
                        block2={<AddToFavorite adId={contentful_id} />}
                    />

                    {ad.year ? (
                        <Line
                            block1={<FormattedMessage id="ad.year" />}
                            block2={<Text fontWeight={"600"}>{ad.year}</Text>}
                        />
                    ) : null}

                    {
                        ad.refBrand && ad.refBrand.slug ?

                            <Line
                                block1={<FormattedMessage id="ad.maker" />}
                                block2={
                                    <Box textAlign="right">
                                        <Link
                                            fontWeight="600"
                                            color="blue.600"
                                            textDecoration="underline"
                                            as={GatsbyLink}
                                            onClick={() => {
                                                this.props.close();
                                            }}
                                            to={ad.refBrand.slug ? `${ad.refBrand.slug}` : `${ad.refBrand.fields.slug}`}
                                        >
                                            {ad.refBrand.name ? ad.refBrand.name : ad.refBrand.fields.name}
                                        </Link>
                                    </Box>
                                }
                            />
                            : null
                    }


                    <Line
                        block1={<FormattedMessage id="ad.direct.link" />}
                        block2={
                            <CopyLink url={`${siteConfig.domains[process.env.GATSBY_LANG]}${ad.slug}`} />
                        }
                    />

                    <Line
                        block1={<FormattedMessage id='ad.send' />}
                        block2={
                            <Button
                                leftIcon={<SiWhatsapp />}
                                size='sm'
                                as='a'
                                href={`whatsapp://send?text=${ad.name} : ${encodeURI(siteConfig.domains[process.env.GATSBY_LANG] + ad.slug)}`}
                                data-action="share/whatsapp/share"
                                target="_blank">
                                WhatsApp
                            </Button>
                            //     onClick={
                            //         () => {
                            //             window.open(
                            //                 "https://www.facebook.com/dialog/share?"
                            //                 + "app_id=209954609027802"
                            //                 + "&display=popup"
                            //                 + "&href="
                            //                 + encodeURI(siteConfig.domains[process.env.GATSBY_LANG] + ad.slug)
                            //                 + "&redirect_uri="
                            //                 + encodeURI(siteConfig.domains[process.env.GATSBY_LANG] + ad.slug)
                            //                 , "Partager l'annonce " + ad.name, "menubar=no, status=no, scrollbars=no, menubar=no, width=600, height=500")
                            //         }
                            //     } size="sm">
                            // </Button>
                        }
                    />


                    <Line
                        block1={<FormattedMessage id="ad.share" />}
                        block2={
                            <Button
                                leftIcon={<SiFacebook />}
                                onClick={
                                    () => {
                                        window.open(
                                            "https://www.facebook.com/dialog/share?"
                                            + "app_id=209954609027802"
                                            + "&display=popup"
                                            + "&href="
                                            + encodeURI(siteConfig.domains[process.env.GATSBY_LANG] + ad.slug)
                                            + "&redirect_uri="
                                            + encodeURI(siteConfig.domains[process.env.GATSBY_LANG] + ad.slug)
                                            , "Partager l'annonce " + ad.name, "menubar=no, status=no, scrollbars=no, menubar=no, width=600, height=500")
                                    }
                                } size="sm"><FormattedMessage id="ad.share" />
                            </Button>
                        }
                    />

                    {ad.user && 1 === 2 ?
                        <>
                            <Divider borderColor="gray.100" />
                            <SellerCard userID={
                                ad.user.sys ? ad.user.sys.id
                                    : ad.user.contentful_id ? ad.user.contentful_id
                                        : null
                                // ad.user && ad.user.sys.id
                                // ? ad.user.sys.id
                                // ? ad.user.contentful_id :  ad.user.contentful_id : null
                            } />
                        </>
                        : null
                    }

                    {ad.country ?

                        <Line
                            block1={
                                <FormattedMessage id="ad.country" />
                            }
                            block2={
                                <Box textAlign="right">
                                    <HStack fontWeight={"600"} justify='flex-end'>
                                        <Box>
                                            {ad.country}
                                        </Box>
                                        <Box>
                                            {countryToFlag(ad.countryCode ? ad.countryCode : ad.country)}
                                        </Box>
                                    </HStack>
                                </Box>
                            }
                        />
                        : null}


                    <Line
                        block1={<FormattedMessage id="ad.region" />}
                        block2={<Text fontWeight={"600"}>{ad.region}</Text>
                        }
                    />

                    {(ad.location) ?
                        <Box
                            mt={10}
                            px={{ base: 3, lg: 2 }}
                        >
                            <Text>
                                <FormattedMessage id="ad.localisation" />
                            </Text>
                            <Image
                                width="100%"
                                maxW='400px'
                                alt="Localisation de votre annonce"
                                loading='lazy'
                                sizes='(max-width: 340px) 320px, 276px'
                                srcSet={
                                    `
                                        https://maps.googleapis.com/maps/api/staticmap?center=${ad.location.lat},${ad.location.lon}&zoom=7&autoscale=1&size=624x300&maptype=roadmap&key=AIzaSyC7O1XSp3BY1qkSUWKhR0hl4mOHcCIxi_U&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:1%7C${ad.location.lat},${ad.location.lon} 350w,
                                        https://maps.googleapis.com/maps/api/staticmap?center=${ad.location.lat},${ad.location.lon}&zoom=7&autoscale=1&size=276x150&maptype=roadmap&key=AIzaSyC7O1XSp3BY1qkSUWKhR0hl4mOHcCIxi_U&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:1%7C${ad.location.lat},${ad.location.lon} 276w`
                                }
                                src={
                                    `https://maps.googleapis.com/maps/api/staticmap?center=${ad.location.lat},${ad.location.lon}&zoom=7&autoscale=1&size=319x150&maptype=roadmap&key=AIzaSyC7O1XSp3BY1qkSUWKhR0hl4mOHcCIxi_U&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:1%7C${ad.location.lat},${ad.location.lon}`
                                }
                            />
                        </Box>
                        : null}
                </Stack>
            </Box>
        </Box>


    )
}