import React, { useState } from "react"
import './annoncesWysiwyg.css'
import { FormattedMessage, FormattedDate } from 'react-intl';
import {
    Box,
    Flex,
    Image,
    Text,
    Button
} from "@chakra-ui/react";
import { BiArrowBack } from "react-icons/bi";


export const ImagePreview = ({
    ad,
    isViewingPictures,
    backAction,
    handleOpenGallery
}) => {
    return (

        <Box
            h='300px'
            position={"relative"}
            zIndex="base"
        >
            {backAction ?
                <Flex position="absolute" top="0" left="0" w="100%" p="1rem" zIndex="tooltip" display={{ base: "flex", "lg": "flex" }} >
                    <Flex
                        borderRadius={'full'}
                        border='solid 1px'
                        borderColor={'whiteAlpha.800'}
                        bgColor='blackAlpha.500'
                        color='whiteAlpha.800'
                        cursor={'pointer'}
                        aria-label='Prev'

                        fontWeight={'500'}
                        px={3}
                        py={2}

                        alignItems={'center'}

                        transition={`transform 200ms ease-in-out, background 200ms ease-in-out`}
                        _hover={{
                            bgColor: 'primary',
                            color: 'white',
                        }}
                        onClick={backAction}
                    >
                        <Box mr={2}>
                            <BiArrowBack />
                        </Box>
                        <FormattedMessage id='main.back' />
                    </Flex>
                </Flex>
                : null}
            <Box
                display={{ base: "flex", "lg": "none" }} cursor="pointer" opacity={1} width="100" position="absolute" w="100%" h="100%" justifyContent="center" alignItems="center"
                _hover={{
                    backgroundColor: "rgba(40,40,40,.4)",
                }}
                onClick={() => handleOpenGallery()}
            >
                <Text textTransform="uppercase" textShadow="0 0 5px rgba(40,40,40, .75)" color="white" letterSpacing=".25rem">
                    {ad.pictures.url.length > 1
                        ?
                        <FormattedMessage id="ad.pictures.plural" values={{ count: ad.pictures.url.length }} />
                        :
                        <FormattedMessage id="ad.pictures" values={{ count: ad.pictures.url.length }} />
                    }
                </Text>
            </Box>
            <Box
                display={{ base: "none", "lg": "flex" }}
                cursor="pointer"
                transition="opacity .5s ease"
                opacity={0.25}
                width="100"
                position="absolute"
                w="100%"
                h="100%"
                justifyContent="center"
                alignItems="center"
                _hover={{
                    backgroundColor: "rgba(40,40,40,.4)",
                    opacity: 1
                }}
                onClick={() => handleOpenGallery()}
            >
                <Text textTransform="uppercase" color="white" letterSpacing=".25rem" borderRadius="50px" p="1rem" background="rgba(50,50,50,.6)">
                    {ad.pictures.url.length > 1 ?
                        <FormattedMessage id="ad.pictures.plural" values={{ count: ad.pictures.url.length }} />
                        :
                        <FormattedMessage id="ad.pictures" values={{ count: ad.pictures.url.length }} />
                    }
                </Text>
            </Box>
            <picture>
                <source
                    type="image/webp"
                    srcSet={
                        `${process.env.GATSBY_BASE_IMG_URL}${ad.pictures.url[0]}?twic=v1/cover=750x750/format=webp/quality=40/crop=750x300@0x350`
                    }
                />
                <source
                    type="image/jpeg"
                    srcSet={
                        `${process.env.GATSBY_BASE_IMG_URL}${ad.pictures.url[0]}?twic=v1/cover=750x750/format=jpeg/quality=40/crop=750x300@0x350`
                    }
                />
                <Image
                    alt={ad.name}
                    // mt="-15rem"
                    h='100%'
                    objectFit="cover"
                    w={"100%"}
                    loading="eager"
                    src={
                        `${process.env.GATSBY_BASE_IMG_URL}${ad.pictures.url[0]}?twic=v1/cover=750x750/format=jpeg/quality=40/crop=750x300@0x350`
                    }
                />
            </picture>
        </Box>

    )
}