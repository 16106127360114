import React, { memo, useEffect, useMemo, useState } from "react"
import logo from "../../static/svg/logo.svg"

import {
    Box,
    Flex,
    Image,
    Button,
    Center,

} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import SwipeableViews from 'react-swipeable-views';
import { virtualize, bindKeyboard } from 'react-swipeable-views-utils';
import { mod } from 'react-swipeable-views-core';

import { scroller, Events } from 'react-scroll';

import { BiLeftArrowAlt, BiRightArrowAlt, BiX } from 'react-icons/bi'
import '../../styles/scrollbar.css'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { setIn } from "formik";
const slides = (ad) => {
    let slidesArray = []

    for (let index = 0; index < ad.pictures.url.length; index++) {
        const image = ad.pictures.url[index];
        slidesArray.push(
            <Box

                id={`image-${index}`}
                key={`image-${index}`}

                minHeight={"300px"}
                bg={"gray.300"}
                w={{ base: '100vw', lg: '750px' }}
                h={{ base: 'auto', lg: '750px' }}
                flexShrink={0}

            >
                <picture>
                    <source
                        type="image/webp"
                        srcSet={`${process.env.GATSBY_BASE_IMG_URL}${image}?twic=v1/cover=750x750/format=webp/quality=50`}
                    />
                    <source
                        type="image/jpeg"
                        srcSet={`${process.env.GATSBY_BASE_IMG_URL}${image}?twic=v1/cover=750x750/format=jpeg/quality=50`}
                    />
                    <Image
                        w={{ base: '100vw', lg: '750px' }}
                        h={{ base: 'auto', lg: '750px' }}
                        objectFit={'cover'}
                        alt={ad.name}
                        loading='eagger'
                        src={`h${process.env.GATSBY_BASE_IMG_URL}${image}?twic=v1/cover=750x750/format=jpeg/quality=70`}
                    />
                </picture>
            </Box>)
    }
    return (slidesArray)

}

// const Swiper = ({ ad }) => {

//     return (

//         <Flex

//             maxW='750px'
//             key={'gallerySwiperWrapper'}
//             // h={{'750px'}}
//             overflowX='scroll'
//             wrap='nowrap'
//             key={'gallerySwiper'}

//             style={
//                 // isMobile ?
//                 {
//                     scrollSnapType: "x mandatory",

//                     webkitOverflowScrolling: "touch",
//                     scrollBehavior: 'smooth',

//                     //webkitTransform: "translateZ(0px)",
//                     //"-webkit-transform": "translate3d(0,0,0)",
//                     //"-webkit-perspective": 1000

//                 }
//                 // : null
//             }
//             id='gallery-swiper'
//             className='no-scrollbar debug-webkbit'
//         >
//             <Slides ad={ad} />
//         </Flex>

//     )
// }

const Gallery = (props) => {

    const [index, setIndex] = useState(0)
    const [autoplay, setAutoplay] = useState(0)

    // Events.scrollEvent.register('end', function (to, element) {
    //     let newIndex = to ? parseInt(to.split('-')[1]) : null;
    //     console.log(newIndex);
    //     setIndex(newIndex);
    // });


    // Add event listeners
    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        // window.addEventListener('keyup', upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
            //   window.removeEventListener('keyup', upHandler);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount

    // If pressed key is our target key then set to true
    const downHandler = ({ key }) => {
        if (key === 'ArrowRight') {
            // handleScroll(index + 1);
            goNext();
        }
        if (key === 'ArrowLeft') {
            // handleScroll(index - 1);
            goPrev();
        }
        if (key === 'Escape') {
            props.close()
        }
    }

    const { isViewingPictures, ad } = props

    // const handleScroll = (targetIndex) => {
    //     // console.log('targetIndex', targetIndex, 'length', ad.pictures.url.length);
    //     let recalculatedIndex =
    //         targetIndex < 0 ? ad.pictures.url.length - 1 :
    //             targetIndex === ad.pictures.url.length ? 0 :
    //                 targetIndex;

    //     // console.log('targetIndex', recalculatedIndex)
    //     scroller.scrollTo(
    //         `image-${recalculatedIndex}`
    //         , {
    //             duration: 400,
    //             delay: 50,
    //             smooth: true,
    //             spy: true,
    //             horizontal: true,
    //             containerId: 'gallery-swiper',

    //         })
    // }

    const updateCurrentSlide = (index) => {
        console.log(index)
        if (index !== index) {
            setIndex(index)
        }
    }

    const goNext = () => {
        console.log(index)

        if (index + 1 < slideNumber) {
            setIndex(parseInt(index) + 1)
        } else {
            setIndex(0)
        }
    }

    const goPrev = () => {

        if (index !== 0) {
            setIndex(parseInt(index) - 1)
        } else {
            setIndex(slideNumber - 1)
        }
    }

    const slideNumber = ad.pictures.url.length

    return (
        <Box
            position="relative"
        >
            <Flex
                w={"100%"}
                p={4}
                zIndex={"modal"}
                justify={"space-between"}
                style={{
                    position: "absolute",
                    // top: "10rem",
                    zIndex: "999999",
                    left: 0,
                    background: "linear-gradient(180deg, rgba(0,0,0,.4) 0%, rgba(0,0,0,0) 100%)"
                }}
            >
                <Flex
                    onClick={props.close}
                    borderRadius={'full'}
                    border='solid 1px'
                    borderColor={'whiteAlpha.800'}
                    bgColor='blackAlpha.500'
                    color='whiteAlpha.800'
                    cursor={'pointer'}
                    aria-label='Prev'

                    fontWeight={'500'}
                    px={3}
                    py={2}

                    alignItems={'center'}

                    transition={`transform 200ms ease-in-out, background 200ms ease-in-out`}

                    _hover={{
                        bgColor: 'primary',
                        color: 'white',
                    }}
                >
                    <Box mr={2}>
                        <BiX />
                    </Box>
                    <FormattedMessage id="ad.gallery.close.the.diaporama" />
                </Flex>
            </Flex>

            <Box
                position={'relative'}
                zIndex='base'
                bgColor='gray.800'
                py={{ base: '74px', lg: 0 }}
                color='white'
            >
                <Carousel
                    showIndicators={false}
                    showStatus={false}
                    showArrows={false}
                    autoPlay={autoplay}
                    selectedItem={index}
                    onChange={updateCurrentSlide}
                    showThumbs={false}

                    emulateTouch={true}
                >
                    {slides(ad)}
                </Carousel>
            </Box>

            {isViewingPictures && ad.pictures ? (
                ad.pictures.url ?
                    ad.pictures.url.length > 1 ?
                        <Flex
                            p={{ base: 4, lg: 10 }}
                            w={"100%"}
                            justify="space-between"
                            bottom=" 0"
                            position="absolute"
                        >
                            <Center
                                borderRadius={'full'}
                                border='solid 1px'
                                borderColor={'whiteAlpha.800'}
                                bgColor='blackAlpha.300'
                                color='whiteAlpha.800'
                                w='40px'
                                h='40px'
                                cursor={'pointer'}
                                onClick={() => {
                                    // setIndex(index + 1)
                                    // handleScroll(index - 1)
                                    goPrev()
                                }}
                                aria-label='Prev'

                                transition={`transform 200ms ease-in-out, background 200ms ease-in-out`}

                                _hover={{
                                    bgColor: 'primary',
                                    color: 'white',
                                    transform: 'scale(1.2)'
                                }}

                            >
                                <BiLeftArrowAlt />
                            </Center>

                            <Center
                                borderRadius={'full'}
                                border='solid 1px'
                                borderColor={'whiteAlpha.800'}
                                bgColor='blackAlpha.300'
                                color='whiteAlpha.800'
                                w='40px'
                                h='40px'
                                cursor={'pointer'}
                                onClick={() => {
                                    // setIndex(index - 1)
                                    // handleScroll(index + 1)
                                    goNext()
                                }}
                                aria-label='Prev'

                                transition={`transform 200ms ease-in-out, background 200ms ease-in-out`}

                                _hover={{
                                    bgColor: 'primary',
                                    color: 'white',
                                    transform: 'scale(1.2)'
                                }}

                            >
                                <BiRightArrowAlt />
                            </Center>
                        </Flex>
                        : null
                    : null
            ) : null}
        </Box>
    )
}

export default Gallery