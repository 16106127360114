import React, { useState } from "react"
import {
    Input,
    Button,
    useClipboard
} from "@chakra-ui/react";
import { FormattedMessage } from 'react-intl';
import { BiLink, BiLinkAlt } from "react-icons/bi";

function CopyLinkButton(props) {
    const [value] = useState(props.url);
    const { onCopy, hasCopied } = useClipboard(value);
    return (
        <>
            <Input display="none" value={value} isReadOnly />
            <Button
                textAlign="right"
                onClick={onCopy}
                size="sm"
                colorScheme="gray"
                leftIcon={<BiLinkAlt />}
            >
                {hasCopied ? <FormattedMessage id="ad.copy.button.ok" /> : <FormattedMessage id="ad.copy.button" />}
            </Button>
        </>
    );
}

export default CopyLinkButton


