import React, { Component, useState } from 'react';
import humanizeDuration from 'humanize-duration';
import {
    Box,
    Button,
    Stack,
    Text,
} from "@chakra-ui/react";
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { UserSmallCard } from '../user/smallCard';

const ResponseDelay = (props) => {
    const { userId } = props
    const [averageDelayDuration, setAverageDelayDuration] = useState()
    const [user, setUser] = useState()
    const [showProfile, setShowProfile] = React.useState(false)

    const userDetailsButton = React.useRef()

    React.useEffect(
        () => {
            axios.get(
                `${process.env.GATSBY_API_URL}/user/average-reply-delay/${userId}`
            )
                .then((response) => {
                    setAverageDelayDuration(response.data.delay);
                    setUser(response.data.name);
                })
        }
    )

    const delay = (duration) => { return (humanizeDuration(duration * 1000, { units: ['h'], language: process.env.GATSBY_LANG, round: true })) }
    const Top = (props) => { return (<Text as="span">{delay(props.delay)}{' '}<span aria-label="top" role="img">👍</span></Text>) }
    const Perfect = (props) => { return (<Text as="span">{delay(props.delay)}{' '}<span aria-label="top" role="img">👌</span></Text>) }
    const Allstar = (props) => { return (<Text as="span">{delay(props.delay)}{' '}<span aria-label="top" role="img">⭐</span></Text>) }
    return (
        <>
            <Stack
            >

                <Button
                    variant={'link'}
                    size='xs'
                    ref={userDetailsButton}
                    whiteSpace='normal'

                    onClick={
                        () => setShowProfile(!showProfile)
                    }
                >
                    {averageDelayDuration ?

                        <Text
                            as='span'
                        >{user} <FormattedMessage id="ad.reply.average.delay" />&nbsp;
                            <Text as='span'>
                                {
                                    averageDelayDuration < 21600 ? <Allstar delay={averageDelayDuration} /> :
                                        averageDelayDuration < 43200 ? <Perfect delay={averageDelayDuration} /> :
                                            averageDelayDuration < 86400 ? <Top delay={averageDelayDuration} />
                                                : <Text as="span">{delay(averageDelayDuration)}</Text>
                                }
                            </Text>
                        </Text>
                        :
                        <Text as='span'>
                            <FormattedMessage id="ad.sold.by" />&nbsp;
                            {user}
                        </Text>

                    }
                </Button>

                {showProfile ?
                    <UserSmallCard userId={userId} />
                    : null}
            </Stack>
        </>
    )

}

export default ResponseDelay