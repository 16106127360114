import React, { useEffect, useState } from 'react'
import { FormattedNumber } from 'react-intl'
import { Badge } from '@chakra-ui/react'

const Price = (props) => {

    const [currency, setCurrency] = useState(null)

    useEffect(() => {
        if (window) {
            // const currency = localStorage.getItem('currency');
            let preferedCurrency = JSON.parse(localStorage.getItem('currency'))
            setCurrency(preferedCurrency)
        }
        else {
            setCurrency({ "name": "EUR", "rate": "1" })
        }

    }, []);

    return (
        <Badge as="span" colorScheme="green" fontSize="sm">
            {
                !props.value ?
                    '-'
                    : currency && currency.rate ?
                        <FormattedNumber
                            value={Math.round(parseInt(props.value) * parseFloat(currency.rate))}
                            format={currency.name}
                            minimumFractionDigits={'0'}
                            maximumFractionDigits={'0'}
                        />
                        :
                        <FormattedNumber
                            value={Math.round(parseInt(props.value))}
                            format={props.currency ? props.currency : 'EUR'}
                            minimumFractionDigits={'0'}
                            maximumFractionDigits={'0'}
                        />}
        </Badge>
    )
}

export default Price