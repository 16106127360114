import { Box, Flex, HStack, Image, Stack, Text, VStack } from '@chakra-ui/react'
import Axios from 'axios';
import * as React from 'react'

import config from '../../../config'
import Debug from '../../utils/Debug';

export const UserSmallCard = ({ userId }) => {

    const [data, setData] = React.useState()

    React.useEffect(
        () => {
            getData();
        },
        [userId]
    )

    const getData = () => {

        Axios.get(
            `${config.apiUrl}/user/${userId}`
        ).then(res => setData(res.data))
    }

    const age = birthDate => {
        var diff = Date.now() - new Date(birthDate).getTime();
        var age = new Date(diff);

        return Math.abs(age.getUTCFullYear() - 1970);
    }

    return (

        data ?


            <VStack>
                {
                    data.profilePict ?
                        <Image
                            src={`${config.apiUrl}/document/${data.profilePict}`}
                            alt={'user'}
                            w='100px'
                            rounded={'full'}
                        />
                        : null
                }

                <Stack>
                    <Text fontWeight='500'>
                        {data.name}
                    </Text>
                    <Text
                        letterSpacing={'wide'}
                        fontSize='xs'
                        color='gray.700'
                    >
                        {data.birthDate ?
                            `${age(data.birthDate)} ans `
                            : null}
                        {data.location.city ? `(${data.location.city})` : null}
                    </Text>
                    <Text fontSize='sm'>{data.presentation}</Text>
                </Stack>
            </VStack>


            : null
    )
}