import { Box, Button, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import * as React from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import Debug from '../../utils/Debug'
import { UserContext } from '../userContext'
import { AdCommentWizard } from './Wizard'

export const PublishCommentButton = ({ adId }) => {

    const [showWizard, setShowWizard] = React.useState(false)

    const user = React.useContext(UserContext) ? React.useContext(UserContext).user : null

    if (!user || !user._id) return null

    return (
        <>
            <Box
                py={4}
                borderTop='solid 1px'
                borderBottom='solid 1px'
                borderColor='gray.300'
                bgColor='primary'
                p={2}
                rounded='3px'
                color='white'
            >

                Vous êtes connaisseur de ce type d'annonce,
                <Button
                    variant='link'
                    size='sm'
                    rightIcon={<BiRightArrowAlt />}
                    onClick={() => setShowWizard(!showWizard)}
                    color='whiteAlpha.800'
                >
                    donnez votre avis pour aider le vendeur
                </Button>
            </Box>

            {showWizard ?
                <Modal
                    // isOpen={!showWizard}
                    isOpen={true}
                    onClose={() => setShowWizard(!showWizard)}
                    size='xl'
                >
                    <ModalOverlay bgColor='whiteAlpha.800' />
                    <ModalContent>
                        <ModalBody
                            py={6}
                        >
                            <AdCommentWizard
                                adId={adId}
                                userId={user._id}
                                cancel={() => setShowWizard(!showWizard)}
                            />
                        </ModalBody>
                    </ModalContent>

                </Modal>
                : null}
        </>
    )
}