import React, { Component } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
    Box,
    Divider,
    Flex,
    Image,
    Text,
    Link,
} from "@chakra-ui/react"


const contentful = require("contentful")

const client = contentful.createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE,
    environment: "master",
    accessToken: process.env.GATSBY_CONTENTFUL_TOKEN
})


class SellerCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null
        }
    }
    componentDidMount() {
        const { userID } = this.props
        console.log('userID', userID)
        client
            .getEntry(userID)
            .then(response =>
                this.setState({
                    data: response.fields
                })
            )
            .catch(console.error)

    }
    render() {
        const { data } = this.state
        return (
            data ?
                <>
                    <Flex justify="space-between">
                        <Box>
                            <Link textDecoration="underline" as={GatsbyLink} to={`${data.slug}`}>{data.name}</Link>
                            {data.hours ?
                                <Text fontSize="12px">{data.hours}</Text>
                                : null}
                        </Box>
                        {data.logo ?
                            <Image loading="lazy" size="50px" h='auto' src={data.logo.fields.file.url} alt={`${data.name} sur Wanaboat`} />
                            : null}
                    </Flex>
                    <Divider borderColor="gray.100" />
                </>
                : null

        )
    }

}

export default SellerCard