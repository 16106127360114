const countryToFlag = (countryCode) => {

    let flag

    switch (countryCode) {
        case 'FRA':
            flag = '🇫🇷'
            break;
        case 'FR':
            flag = '🇫🇷'
            break;
        case 'France':
            flag = '🇫🇷'
            break;
        case 'BEL':
            flag = '🇧🇪'
        case 'BE':
            flag = '🇧🇪'
            break;
        case 'GER':
            flag = "🇩🇪"
            break;
        case 'DE':
            flag = "🇩🇪"
            break;
        case 'CHE':
            flag = '🇨🇭'
            break;
        case 'CH':
            flag = '🇨🇭'
            break;
        case 'GBR':
            flag = '🇨🇬'
            break;
        case 'NZL':
            flag = '🇦🇺'
            break;
        case 'AUS':
            flag = '🇦🇺'
            break;
        case 'AU':
            flag = '🇦🇺'
            break;
        case 'USA':
            flag = '🇺🇸'
            break;
        case 'NLD':
            flag = '🇳🇱'
            break;
        case 'NL':
            flag = '🇳🇱'
            break;
        case 'ITA':
            flag = '🇮🇹'
            break;
        case 'IT':
            flag = '🇮🇹'
            break;
        case 'ESP':
            flag = '🇪🇸'
            break;
        case 'SP':
            flag = '🇪🇸'
            break;
        case 'RSA':
            flag = '🇿🇦'
            break;
        case 'ZAF':
            flag = '🇿🇦'
            break;
        case 'POL':
            flag = '🇵🇱'
            break;
        case 'EST':
            flag = '🇪🇪'
            break;
        case 'AUT':
            flag = '🇦🇹'
            break;
        case 'PTR':
            flag = '🇵🇹'
            break;
        case 'PRT':
            flag = '🇵🇹'
            break;
        case 'PT':
            flag = '🇵🇹'
            break;
        case 'LUX':
            flag = '🇵🇱'
            break;
        case 'CAN':
            flag = '🇨🇦'
            break;
        case 'SWE':
            flag = '🇸🇪'
            break;
        case 'CZE':
            flag = '🇸🇨'
            break;
        case 'MTQ':
            flag = '🇫🇷'
            break;


        default:
            flag = '🏴‍☠️'
            break;
    }

    return flag
}

export default countryToFlag